import React from "react";
import { TextField, Button, Container, Paper, Typography } from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup"; // Import Yup for validation
import axios from "axios";
import { useNavigate } from "react-router-dom";

// Validation schema using Yup
const validationSchema = Yup.object({
  camera_link: Yup.string()
    .required("Camera ID is required"),
});

function AddCamera() {
  const navigate = useNavigate();
  const formik = useFormik({
    initialValues: {
      camera_link: "",
    },
    validationSchema: validationSchema, // Apply the validation schema
    onSubmit: async (values) => {
      try {
        const jwtToken = localStorage.getItem("isLoggedIn");
        const config = {
          headers: {
            Authorization: `Bearer ${jwtToken}`,
          },
        };

        const res = await axios.post(
          "http://13.202.42.73:8003/inset_camera_link",
          values,
          config
        );
        console.log(res);
        if (res.data.email) {
          alert("Camera link added successfully");
          localStorage.setItem("cameraAdded", "true"); // Set the flag to indicate the camera was added
          navigate("/dashboard");
        }
      } catch (error) {
        console.error("Error posting camera link:", error);
      }
    },
  });

  return (
    <Container
      maxWidth="sm"
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "60vh",
      }}
    >
      <Paper elevation={3} style={{ padding: "40px", width: "100%" }}>
        <Typography variant="h4" component="h1" align="center" gutterBottom>
          Add Camera
        </Typography>
        <form onSubmit={formik.handleSubmit}>
          <TextField
            size="medium"
            margin="normal"
            variant="outlined"
            fullWidth
            id="camera_link"
            name="camera_link"
            label="Camera ID"
            value={formik.values.camera_link}
            onChange={formik.handleChange}
            error={
              formik.touched.camera_link && Boolean(formik.errors.camera_link)
            }
            helperText={formik.touched.camera_link && formik.errors.camera_link}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            style={{
              padding: "10px",
              marginTop: "20px",
            }}
          >
            Submit
          </Button>
        </form>
      </Paper>
    </Container>
  );
}

export default AddCamera;
