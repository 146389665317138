import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import CircularProgress from "@mui/material/CircularProgress";
import { makeStyles } from "@mui/styles";
import progress from "../assets/images/progress3.gif";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(4),
    textAlign: "center",
    maxWidth: 400,
    borderRadius: 8,
  },
  image: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
}));

export default function DetectionAlert({ detectionAlert }) {
  const classes = useStyles();

  return (
    <Modal
      open={detectionAlert}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      className={classes.modal}
    >
      <Box className={classes.paper}>
        <img src={progress} alt="" className={classes.image} />
        <Typography variant="h6" gutterBottom>
          गिनती प्रक्रिया में है, कृपया प्रतीक्षा करें
        </Typography>
        <Typography variant="body1" color="textSecondary">
          Detection process is running, please wait
        </Typography>
       
      </Box>
    </Modal>
  );
}
