import "../../../styles/throughput.scss";
import { useEffect, useState } from "react";
import axios from "axios";
import jsPDF from "jspdf";
import "jspdf-autotable";
import img from "../../../assets/images/Count.png";
import bg from "../../../assets/images/bg.png";
import JSZip from "jszip";
import saveAs from "file-saver";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import { IconButton, Tooltip } from "@mui/material";
import LocalPrintshopIcon from "@mui/icons-material/LocalPrintshop";
import EditTruckNumber from "../../../modals/EditTruckNumber";
import { Circles } from 'react-loader-spinner'; 
export default function PipeReport(props) {
  const [truckId, setTruckId] = useState("");
  const [table, setTable] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false); // State for loader

  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);

  const formattedDate = props.date.split("-").reverse().join("-");

  useEffect(() => {
    async function getTables() {
      try {
        const token = localStorage.getItem("isLoggedIn");
        setIsLoading(true); // Show loader

        const res = await axios.get(
          `http://3.108.63.216:8003/user-counts/${props.date}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        const data = res.data;
        console.log(data);

        // Filter the data to include only rows with truck_number
        const filteredData = data.filter(item => item.Truck_Number && item.Truck_Number.length > 0);
        setTable(filteredData.reverse());
      } catch (error) {
        console.error("Error:", error);
      } finally {
        setIsLoading(false); // Hide loader
      }
    }

    getTables();
  }, [props.date]);

  const handleDownloadReport = async () => {
    if (table.length === 0) {
      alert(`No records found for ${formattedDate}`);
      return;
    }
    
    setIsLoading(true); // Show loader

    // Get the JSON data from the rows
    const jsonData = table.map((row) => ({
      ID: row.ID,
      Date: row.Date,
      Time: row.Time,
      "Truck Number": row.Truck_Number,
      // "Incorrect Truck Number": row.incorrect_truck_number || 'NA',
      // "Correct Truck Number": row.correct_truck_number || 'NA',
      "Total Pipes per Vehicle": row.Count,
    }));

    // Initialize the PDF instance
    const pdf = new jsPDF("l", "pt", "a4", true);

    // Add the background color up to 20% from the top
    const headerHeight = pdf.internal.pageSize.getHeight() * 0.2;
    pdf.rect(0, 0, pdf.internal.pageSize.getWidth(), headerHeight, "F");

    // Add the header background image
    const headerBgImageData = bg;
    pdf.addImage(
      headerBgImageData,
      "PNG",
      0,
      0,
      pdf.internal.pageSize.getWidth(),
      headerHeight
    );

    const textY = headerHeight / 2;

    // Calculate the x-coordinate for aligning the headings to the right
    const headingX = pdf.internal.pageSize.getWidth() - 40;

    const titleText = "Alluvium IOT Solutions Private Limited";
    const subtitleText = `Total Pipe Count Report on ${props.date
      .split("-")
      .reverse()
      .join("-")}`;
    // const machineText = "Machine 1";

    pdf.setFontSize(24);
    pdf.setTextColor("#ffffff");
    pdf.text(titleText, headingX, textY - 10, null, null, "right");
    pdf.setFontSize(14);
    pdf.text(subtitleText, headingX, textY + 10, null, null, "right");
    pdf.setFontSize(12);
    // pdf.text(machineText, headingX, textY + 30, null, null, "right");

    // Convert the JSON data to arrays of headers and rows
    const headers = [
      "ID",
      "Date",
      "Time",
       "Truck Number",
      // "Incorrect Truck Number",
      // "Correct Truck Number",
      "Total Pipes per Vehicle",
    ];
    const rowsData = jsonData.map((item, index) => {
      return [
        {
          content: item["ID"],
          styles: {
            fillColor: index % 2 === 0 ? "#F0F0F0" : "#F8F8F8",
          },
        },
        {
          content: item["Date"],
          styles: {
            fillColor: index % 2 === 0 ? "#F0F0F0" : "#F8F8F8",
          },
        },
        {
          content: item["Time"],
          styles: {
            fillColor: index % 2 === 0 ? "#F0F0F0" : "#F8F8F8",
          },
        },
        {
          content: item["Truck Number"],
          styles: {
            fillColor: index % 2 === 0 ? "#F0F0F0" : "#F8F8F8",
          },
        },
        // {
        //   content: item["Incorrect Truck Number"],
        //   styles: {
        //     fillColor: index % 2 === 0 ? "#F0F0F0" : "#F8F8F8",
        //   },
        // },
        // {
        //   content: item["Correct Truck Number"],
        //   styles: {
        //     fillColor: index % 2 === 0 ? "#F0F0F0" : "#F8F8F8",
        //   },
        // },
        {
          content: item["Total Pipes per Vehicle"],
          styles: {
            fillColor: index % 2 === 0 ? "#F0F0F0" : "#F8F8F8",
          },
        },
      ];
    });

    // Add the table using pdf.autoTable
    pdf.autoTable({
      startY: headerHeight + 20,
      head: [headers],
      body: rowsData,
      styles: {
        halign: "center",
        valign: "middle",
        fontSize: 12,
        cellPadding: 5,
      },
      headStyles: {
        fillColor: "#484848",
        textColor: "#fff",
        fontStyle: "bold",
      },
      columnStyles: {
        Timing: { fontStyle: "bold" },
        "Production Count": { fontStyle: "bold" },
      },
    });

    // Add another image at the footer
    const footerImgData = img;
    const footerImgWidth = 150; // Adjust the width of the footer image as needed
    const footerImgHeight = 20;
    const footerTextX = 40 + footerImgWidth + 10;

    // Set the line color to light gray (R, G, B values)
    pdf.setDrawColor(200, 200, 200);

    // Calculate the Y-coordinate for the line
    const lineY = pdf.internal.pageSize.getHeight() - footerImgHeight - 30; // Adjust the value to control the vertical position of the line

    // Draw the horizontal line
    pdf.line(40, lineY, pdf.internal.pageSize.getWidth() - 40, lineY);

    pdf.addImage(
      footerImgData,
      "PNG",
      40,
      pdf.internal.pageSize.getHeight() - footerImgHeight - 20,
      footerImgWidth,
      footerImgHeight
    );

    const footerTextY =
      pdf.internal.pageSize.getHeight() - footerImgHeight - 17; // Adjust the value to control the vertical position of the text
    const companyText = "Alluvium IOT Solutions Pvt. Ltd.";
    const addressText = "A-306, Wallstreet 2, Ellisbridge";
    const cityText = "Ahmedabad, Gujarat";
    const contactText = "+91 9924300511";

    pdf.setFontSize(8);
    pdf.setTextColor("#000000");
    pdf.text(companyText, footerTextX, footerTextY, null, null, "left");
    pdf.text(addressText, footerTextX, footerTextY + 10, null, null, "left");
    pdf.text(cityText, footerTextX, footerTextY + 20, null, null, "left");
    pdf.text(contactText, footerTextX, footerTextY + 30, null, null, "left");

    // Save the PDF file
    const filename = `${props.date}.pdf`;
    pdf.save(filename);
    
    setIsLoading(false); // Hide loader
  };

  const handleDownloadAllImages = async () => {
    if (table.length === 0) {
      alert(`No images found for ${formattedDate}`);
      return;
    }
    
    setIsLoading(true); // Show loader

    const zip = new JSZip();
    const imageFolder = zip.folder("Images");

    // Prepare all the promises for fetching images
    const fetchPromises = table.map((data, index) => {
      // Cache-busting parameter
      const cacheBuster = `?t=${new Date().getTime()}`;

      // Fetch original image
      const originalImagePromise = axios
        .get(`${data.Original_Image_URL}${cacheBuster}`, { responseType: "arraybuffer" })
        .then((response) => {
          // Get the image filename from the URL
          const urlParts = data.Original_Image_URL.split("/");
          const filename = urlParts[urlParts.length - 1];
          const uniqueFilename = `original_image_${index}_${filename}`;
          imageFolder.file(uniqueFilename, response.data);
        })
        .catch((error) => {
          console.error("Error downloading original image:", error);
          console.error("Image URL:", data.Original_Image_URL);
        });

      // Fetch processed image
      const processedImagePromise = axios
        .get(`${data.Processed_Image_URL}${cacheBuster}`, { responseType: "arraybuffer" })
        .then((response) => {
          // Get the image filename from the URL
          const urlParts = data.Processed_Image_URL.split("/");
          const filename = urlParts[urlParts.length - 1];
          const uniqueFilename = `processed_image_${index}_${filename}`;
          imageFolder.file(uniqueFilename, response.data);
        })
        .catch((error) => {
          console.error("Error downloading processed image:", error);
          console.error("Image URL:", data.Processed_Image_URL);
        });

      return Promise.all([originalImagePromise, processedImagePromise]);
    });

    // Wait for all images to be fetched
    await Promise.all(fetchPromises);

    // Generate and save the zip file
    zip.generateAsync({ type: "blob" }).then((blob) => {
      saveAs(blob, "Images.zip");
      setIsLoading(false); // Hide loader
    });
  };

  const handlePrint = (imageUrl) => {
    const img = new Image();
    img.src = imageUrl;

    // When the image loads, trigger print directly
    img.onload = () => {
      const printElement = document.createElement("div");
      printElement.innerHTML = `
        <html>
          <head>
            <title>Print Image</title>
          </head>
          <body>
            <img src="${imageUrl}" style="max-width: 100%;" />
          </body>
        </html>
      `;

      const printWindow = window.open("", "_blank");
      printWindow.document.open();
      printWindow.document.write(printElement.innerHTML);
      printWindow.document.close();

      // Trigger print after a short delay to ensure image rendering
      setTimeout(() => {
        printWindow.print();
        printWindow.close();
      }, 500);
    };
  };

  return (
    <>
      <div style={{ textAlign: "center", marginBottom: "40px" }}>
        <h2 style={{ color: "#273041", marginBottom: "10px" }}>
          Alluvium IOT Solutions Private Limited
        </h2>
        <p style={{ color: "#546e7a", marginBottom: "30px", fontSize: "18px" }}>
          Daily Report
        </p>

        <div style={{ display: "flex", justifyContent: "center" }}>
          <button
            className="download-btn"
            style={{
              marginRight: "20px",
              backgroundColor: "#4caf50",
              color: "#fff",
              border: "none",
              padding: "10px 20px",
              borderRadius: "5px",
              cursor: "pointer",
            }}
            onClick={() => handleDownloadReport()}
          >
            Download Report
          </button>

          <button
            className="download-btn"
            style={{
              backgroundColor: "#2196f3",
              color: "#fff",
              border: "none",
              padding: "10px 20px",
              borderRadius: "5px",
              cursor: "pointer",
            }}
            onClick={() => handleDownloadAllImages()}
          >
            Download All Images
          </button>
        </div>
      </div>

      {isLoading ? ( 
  <div style={{ 
    display: "flex", 
    justifyContent: "center", 
    alignItems: "center", 
    height: "40vh" 
  }}>
    <Circles
      height="80"
      width="80"
      color="#4b8b3b"
      ariaLabel="loading"
    />
  </div>
)  : table.length !== 0 ? (
        <div className="table-container">
          <table
            className="table table-bordered throughput-table"
            style={{ backgroundColor: "#f9f9f9" }}
          >
            <thead style={{ backgroundColor: "#4b8b3b", color: "#fff" }}>
              <tr>
                <th>ID</th>
                <th>Date</th>
                <th>Time</th>
                 <th>Truck Number</th>
                {/* <th>Incorrect Truck Number</th>
                <th>Correct Truck Number</th>  */}
                <th>Original Image</th>
                <th>Processed Image</th>
                <th>Number of Pipes</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {table.map((data, id) => {
                return (
                  <tr key={id}>
                    <td>{data.ID}</td>
                    <td>{data.Date}</td>
                    <td>{data.Time}</td>
                    <td>{data.Truck_Number}</td>
                    {/* <td>{data.incorrect_truck_number}</td>
                    <td>{data.correct_truck_number}</td>  */}
                    <td>
                      <a
                        href={data.Original_Image_URL}
                        className="download-image-link"
                      >
                        <img
                          src={data.Original_Image_URL}
                          alt={`Original Image ${data.ID}`}
                          style={{ maxWidth: "100px", maxHeight: "100px" }}
                        />
                      </a>
                    </td>
                    <td>
                      <a
                        href={data.Processed_Image_URL}
                        className="download-image-link"
                      >
                        <img
                          src={data.Processed_Image_URL}
                          alt={`Processed Image ${data.ID}`}
                          style={{ maxWidth: "100px", maxHeight: "100px" }}
                        />
                      </a>
                    </td>
                    <td>{data.Count}</td>
                    <td>
                      <Tooltip title="Edit truck number">
                        <IconButton
                          onClick={() => {
                            setTruckId(data.id);
                            handleOpenModal();
                          }}
                        >
                          <ModeEditIcon style={{ color: "#007bff" }} />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Print original image">
                        <IconButton
                          onClick={() => handlePrint(data.Original_Image_URL)}
                        >
                          <LocalPrintshopIcon style={{ color: "#28a745" }} />
                        </IconButton>
                      </Tooltip>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      ) : (
        <div style={{ textAlign: "center", fontSize: "20px" }}>
          No records found for {formattedDate}
        </div>
      )}

      <EditTruckNumber
        openModal={openModal}
        handleCloseModal={handleCloseModal}
        truckId={truckId}
      />
    </>
  );
}
