import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import axios from "axios";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80%",
  bgcolor: "background.paper",
  boxShadow: 24,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justfiyContent: "center",
};

export default function NonPipeModal(props) {
  const [truckNo, setTruckNo] = React.useState("");

  const saveImage = async () => {
    if (truckNo === "") {
      alert("Please enter truck number");
    } else {
      const res = await axios.post(
        "http://13.202.42.73:8001/insert_non_pipe_data",
        {
          truck_number: truckNo,
        }
      );
      console.log(res.data);
      props.setNonPipeModal(false);
    }
  };

  React.useEffect(() => {
    setTruckNo(props.non_pipe_number_plate);
  }, [props.non_pipe_number_plate]);

  return (
    <div>
      <Modal
        open={props.nonPipeModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <img
            src={`data:image/png;base64,${props.nonPipeImage}`}
            alt="Base64 Image"
            width="100%"
            style={{ marginBottom: "20px" }}
          />

          <input
            type="text"
            value={truckNo}
            placeholder="Enter Truck Number"
            onChange={(e) => setTruckNo(e.target.value)}
          />
          <div style={{ display: "flex", paddingBottom: "10px" }}>
            <button className="download-btn" onClick={saveImage}>
              Save
            </button>

            <button
              className="download-btn"
              style={{ marginLeft: "10px" }}
              onClick={props.handleCloseNonPipeModal}
            >
              Close
            </button>
            
          </div>
        </Box>
      </Modal>
    </div>
  );
}
