import React, { useContext, useState } from "react";
import { Button, IconButton, InputAdornment, Typography } from "@mui/material";
import { useFormik } from "formik";
import { TextField } from "@mui/material";
import { validationSchema } from "../schemas/LoginSchema";
import { UserContext } from "../contexts/UserContext";
import axios from "axios";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

const LoginForm = () => {
  const { setIsLoggedIn, handleLogin } = useContext(UserContext);
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },

    validationSchema: validationSchema,
    onSubmit: async (values, { setErrors }) => {
      const formData = new FormData();
      formData.append("username", values.email.trim());
      formData.append("password", values.password);
      try {
        const res = await axios.post(
          "http://13.202.42.73:8003/auth/jwt/login",
          formData
        );
        console.log(res.data);
        if (res.data.access_token) {
          setIsLoggedIn(res.data.access_token);
          localStorage.setItem("isLoggedIn", res.data.access_token);
          localStorage.setItem('userRole', res.data.role); // Set reload flag
          navigate("/dashboard"); // Navigate to dashboard
        }
      } catch (error) {
        setErrors({ email: "Incorrect email or password", password: "Incorrect email or password" });
      }
    },
  });

  return (
    <form onSubmit={formik.handleSubmit} style={{ maxWidth: "400px", margin: "auto", padding: "20px", borderRadius: "10px", boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08)" }}>
      <Typography variant="h5" gutterBottom style={{ marginBottom: "20px", textAlign: "center" }}>Login</Typography>
      <TextField
        size="small"
        margin="dense"
        variant="outlined"
        fullWidth
        id="email"
        name="email"
        label="Email"
        type="email"
        value={formik.values.email}
        onChange={formik.handleChange}
        error={formik.touched.email && Boolean(formik.errors.email)}
        helperText={formik.touched.email && formik.errors.email}
        style={{ marginBottom: "20px" }}
      />
      <TextField
        type={showPassword ? "text" : "password"}
        size="small"
        margin="dense"
        variant="outlined"
        fullWidth
        id="password"
        name="password"
        label="Password"
        value={formik.values.password}
        onChange={formik.handleChange}
        error={formik.touched.password && Boolean(formik.errors.password)}
        helperText={formik.touched.password && formik.errors.password}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
                edge="end"
                size="small"
              >
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          ),
        }}
        style={{ marginBottom: "20px" }}
      />

      <Button
        fullWidth
        type="submit"
        variant="contained"
        color="primary"
        size="large"
        style={{ background: "linear-gradient(to right, #D31027, #EA384D)", borderRadius: "25px" }}
      >
        Login
      </Button>
    </form>
  );
};

export default LoginForm;
