import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { Container } from "react-bootstrap";
import { useFormik } from "formik";
import { validationSchema } from "../schemas/TruckValidationSchema";
import axios from "axios";
import { TextField, Tooltip, CircularProgress } from "@mui/material";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import ImageModal from "./ImageModal";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  height: "90%",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 3,
  overflow: "scroll",
};

export default function TruckDetails(props) {
  const [clickCounter, setClickCounter] = useState(0);
  const [language, setLanguage] = useState("hindi");
  const [openImageModal, setOpenImageModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleCloseImageModal = () => {
    setOpenImageModal(false);
  };

  const formik = useFormik({
    initialValues: {
      truck_number: "",
      pipe_count: "",
    },

    validationSchema: validationSchema,

    onSubmit: async (values) => {
      if (values.truck_number !== "") {
        async function handleSnapshot() {
          try {
            const token = localStorage.getItem("isLoggedIn");

            const res = await axios.post(
              `http://3.108.63.216:8003/add-truck-number`,
              {
                truck_number: values.truck_number,
                circles: clickCounter,
              },
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              }
            );

            console.log("insert data", res);
            props.setCount(0);
            props.handleCloseModal();
          } catch (error) {
            console.error("Error:", error);
          }
        }

        await handleSnapshot().then(() => window.location.reload());
      } else {
        alert("Please enter truck number");
      }
    },
  });


  useEffect(() => {
    formik.setFieldValue("truck_number", props.number_plate);
  }, [props.number_plate]);

  const handleTruckNoChange = (event) => {
    const newValue = event.target.value.toUpperCase(); // Convert to uppercase
    formik.handleChange(event); // Handle Formik's onChange event
    formik.setFieldValue("truck_number", newValue); // Update the formik field value
  };

  const handleLanguageChange = (event) => {
    setLanguage(event.target.value);
  };

  const handleFormReset = () => {
    formik.resetForm();
  };

  const getImageSrc = (image) => {
    if (image.startsWith("data:image/")) {
      return image;
    } else {
      return image;
    }
  };

  const handleImageClick = () => {
    props.handleCloseModal();
    setOpenImageModal(true);
  };

  return (
    <div>
      <Modal
        open={props.openModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Enter details
          </Typography>

          <FormControl>
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              defaultValue="hindi"
              name="radio-buttons-group"
              onChange={handleLanguageChange}
            >
              <div style={{ display: "flex" }}>
                <FormControlLabel
                  value="hindi"
                  control={<Radio />}
                  label="हिंदी"
                />
                <FormControlLabel
                  value="english"
                  control={<Radio />}
                  label="English"
                />
              </div>
            </RadioGroup>
          </FormControl>

          <Container style={{ margin: "20px 0" }}>
            <form onSubmit={formik.handleSubmit} style={{ width: "100%" }}>
              <TextField
                size="small"
                margin="dense"
                variant="filled"
                fullWidth
                id="truck_number"
                name="truck_number"
                label={
                  language === "english"
                    ? "Enter truck number"
                    : "ट्रक नंबर दर्ज करें"
                }
                value={formik.values.truck_number}
                onChange={handleTruckNoChange}
                error={
                  formik.touched.truck_number &&
                  Boolean(formik.errors.truck_number)
                }
                helperText={
                  formik.touched.truck_number && formik.errors.truck_number
                }
                inputProps={{ maxLength: 10 }}
              />

              <br />
              <br />
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  backgroundColor: "#f0f0f0",
                  padding: "10px",
                  borderRadius: "8px",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                  marginBottom: "20px",
                }}
              >
                <Typography
                  variant="h6"
                  component="div"
                  sx={{
                    color: "#ff1744",
                    fontWeight: "bold",
                    textAlign: "center",
                  }}
                >
                  {language === "english"
                    ? `Count Number: ${props.pipeCount}`
                    : `गिनती संख्या: ${props.pipeCount}`}
                </Typography>
              </Box>

              {props.processImage && (
                <Tooltip title="Click to view image" position="bottom">
                  <img
                    src={getImageSrc(props.processImage)}
                    alt="Description of the image"
                    width="100%"
                    onClick={handleImageClick}
                    style={{ cursor: "pointer" }}
                  />
                </Tooltip>
              )}

              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <button type="submit" className="download-btn" disabled={isLoading}>
                  {isLoading ? (
                    <CircularProgress size={24} />
                  ) : language === "english" ? "Submit" : "स्वीकार करें"}
                </button>
              </div>

              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginTop: "10px",
                }}
              >
                <button
                  className="download-btn"
                  style={{ marginRight: "10px" }}
                  onClick={handleFormReset}
                >
                  {language === "english" ? "Reset" : "रीसेट"}
                </button>
                <button
                  className="download-btn"
                  onClick={props.handleCloseModal}
                >
                  {language === "english" ? "Close" : "बंद करें"}
                </button>
              </div>
            </form>
          </Container>
        </Box>
      </Modal>
      <ImageModal
        openImageModal={openImageModal}
        handleCloseImageModal={handleCloseImageModal}
        processImage={props.processImage}
        clickCounter={clickCounter}
        setClickCounter={setClickCounter}
        handleCloseParentModal={props.handleCloseModal} // Pass the parent modal close function
      />
    </div>
  );
}
