import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { Container, Col, Row, Button, Tabs, Tab } from "react-bootstrap";
import "../styles/dashboard.scss";
import StampOverview from "./Stamp/dashboardComponents/Overview";
import { DataGrid } from "@mui/x-data-grid";
import DetectionAlert from "../modals/DetectionAlert";
import TruckDetails from "../modals/TruckDetails";
import NonPipeModal from "./NonPipeModal";
import { useNavigate } from "react-router-dom";

function Dashboard(props) {
  const navigate = useNavigate();
  const [tables, setTables] = useState([]);
  const [pipeCount, setCount] = useState(0);
  const [processImage, setProcessImage] = useState("");
  const [nonPipeImage, setNonPipeImage] = useState("");
  const [numberPlate, setNumberPlate] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [nonPipeModal, setNonPipeModal] = useState(false);
  const [isNewUser, setIsNewUser] = useState(false);
  const [detectionAlert, setDetectionAlert] = useState(false);
  const [isTablesLoading, setIsTablesLoading] = useState(true);
  const [isVideoLoading, setIsVideoLoading] = useState(true);
  const canvasRef = useRef(null);

  const handleCloseDetectionAlert = () => setDetectionAlert(false);

  const fetchTables = async (date) => {
    try {
      const token = localStorage.getItem("isLoggedIn");
      const res = await axios.get(
        `http://13.202.42.73:8003/user-counts/${date}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const dataWithTruck = res.data.filter(
        (row) => row.Truck_Number && row.Truck_Number.length > 0
      );

      const data = dataWithTruck.map((row, index) => ({
        ...row,
        id: row.id || index + 1,
      }));

      setTables(data.reverse());
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setIsTablesLoading(false);
    }
  };

  const fetchVideo = async () => {
    try {
      const token = localStorage.getItem("isLoggedIn");
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      const res = await axios.get(
        "http://13.202.42.73:8003/accessing_camera_link",
        config
      );

      if (res.data.message === "No Camera link") {
        navigate("/add-camera");
      }
    } catch (error) {
      console.error("Error fetching video:", error);
    } finally {
      setIsVideoLoading(false);
    }
  };

  const checkIfNewUser = () => {
    const isNew = localStorage.getItem("isNewUser");
    if (isNew) {
      setIsNewUser(true);
      localStorage.removeItem("isNewUser");
    }
  };

  useEffect(() => {
    fetchTables(props.date);
    fetchVideo();
    checkIfNewUser();
  }, [props.date, navigate]);

  useEffect(() => {
    if (isNewUser) {
      navigate("/add-camera");
    }
  }, [isNewUser, navigate]);

  useEffect(() => {
    const reloadRequired = localStorage.getItem("reloadRequired");
    const cameraAdded = localStorage.getItem("cameraAdded");

    if (reloadRequired || cameraAdded) {
      localStorage.removeItem("reloadRequired");
      localStorage.removeItem("cameraAdded");
      window.location.reload();
    }
  }, []);

  const totalPipes = tables.reduce((a, b) => a + (parseInt(b.Count) || 0), 0);

  const vehicleCount = tables.length;

  const columns = [
    { field: "id", headerName: "ID", width: 150, align: "center" },
    { field: "Date", headerName: "Date", width: 150, align: "center" },
    { field: "Time", headerName: "Time", width: 150, align: "center" },
    { field: "Truck_Number", headerName: "Truck Number", width: 150, align: "center" },
    { field: "Count", headerName: "Number of Pipes", width: 150, align: "center" },
  ];

  const captureFrame = async () => {
    try {
      const token = localStorage.getItem("isLoggedIn");

      if (!token) {
        console.error('Token is missing');
        return null;
      }

      const response = await axios.get('http://13.202.42.73:8003/capture_frame', {
        headers: {
          'accept': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      });

      console.log('Response from capture_frame:', response);

      if (response.status === 200) {
        if (response.data.frame) {
          console.log('Base64 frame data:', response.data.frame);  // Log the base64 frame data to the console
          return response.data.frame;
        } else if (typeof response.data === 'string' && response.data.includes('<!DOCTYPE html>')) {
          console.error('Received HTML instead of JSON. Ngrok tunnel may have expired.');
          throw new Error('Invalid response format');
        } else {
          console.error('Unexpected response structure:', response.data);
          throw new Error('Invalid response format');
        }
      } else {
        throw new Error(`Unexpected status code: ${response.status}`);
      }
    } catch (error) {
      console.error('Error capturing frame:', error);
      return null;
    }
  };

  const detectCircles = async () => {
    const token = localStorage.getItem("isLoggedIn");
  
    if (!token) {
      console.error('Token is missing');
      return;
    }
  
    setDetectionAlert(true);
  
    try {
      const base64Image = await captureFrame();
  
      if (!base64Image) {
        alert('Failed to capture image');
        setDetectionAlert(false);
        return;
      }
  
      const requestData = {
        base64_image: base64Image,
      };
  
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      };
  
      const response = await axios.post(
        "http://13.202.42.73:8003/count-with-yolo",
        requestData,
        config
      );
  
      if (response.status === 200) {
        if (response.data.Count !== 0 ) {
          setCount(response.data.Count);
          setProcessImage(response.data.Processed_Image_URL);
          setOpenModal(true);
        } else {
          alert("No pipes detected");
        }
      } else if (response.status === 403) {
        alert('Access forbidden: Requires admin role');
      } else {
        console.error('Unexpected response status:', response.status);
      }
    } catch (error) {
      console.error('Error detecting circles:', error);
      if (error.response && error.response.status === 403) {
        alert('Access forbidden: Requires admin role');
      } else {
        alert('No pipes detected');
      }
    } finally {
      setDetectionAlert(false);
    }
  };
  

  const handleCloseModal = () => {
    setOpenModal(false);
    setDetectionAlert(false);
  };

  const handleCloseNonPipeModal = () => {
    setNonPipeModal(false);
    setNonPipeImage("");
  };

  return (
    <>
      <Container fluid className="dashboard">
        <Row className="dashboard-row">
          <Col xs={12} lg={6} className="dashboard-col">
            {isTablesLoading ? (
              <div>Loading...</div>
            ) : (
              <StampOverview totalPipes={totalPipes} vehicleCount={vehicleCount} />
            )}
          </Col>
          <Col xs={12} lg={6} className="dashboard-col">
            <div className="chart column-chart img-container">
              <div className="roi"></div>
              {isVideoLoading ? (
                <div>Loading...</div>
              ) : (
                <img
                  src="http://13.202.42.73:8003/video_feed"
                  autoPlay
                  muted
                  className="video-feed"
                />
              )}
              <canvas ref={canvasRef} style={{ display: 'none' }}></canvas>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <div className="button-container">
                  <Button
                    className="snapshot-btn"
                    onClick={detectCircles}
                  >
                    Take Snapshot
                  </Button>
                </div>
              </div>
            </div>
          </Col>
        </Row>
        <br />

        <Tabs defaultActiveKey="pipe-data" className="dashboard-tabs">
          <Tab eventKey="pipe-data" title="Report">
            {isTablesLoading ? (
              <div>Loading...</div>
            ) : (
              <DataGrid
                getRowId={(row) => row.id}
                sx={{
                  "& .MuiDataGrid-row:hover": {
                    backgroundColor: "#f0f8ff",
                    transform: "scale(1.01)",
                    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                  },
                  "& .MuiDataGrid-columnHeaders": {
                    backgroundColor: "#4B8B3B",
                    color: "#fff",
                  },
                  "& .MuiDataGrid-cell": {
                    textAlign: "center",
                  },
                }}
                className="table expense-table"
                headerAlign="center"
                rows={tables}
                columns={columns}
                rowsPerPageOptions={[25]}
                getRowHeight={() => "auto"}
                autoHeight
                pageSize={25}
                disableSelectionOnClick
              />
            )}
          </Tab>
        </Tabs>
      </Container>

      <TruckDetails
        openModal={openModal}
        handleCloseModal={handleCloseModal}
        setDetectionAlert={setDetectionAlert}
        handleCloseDetectionAlert={handleCloseDetectionAlert}
        pipeCount={pipeCount}
        setCount={setCount}
        processImage={processImage}
        numberPlate={numberPlate}
      />

      <DetectionAlert
        detectionAlert={detectionAlert}
        setDetectionAlert={setDetectionAlert}
        handleCloseDetectionAlert={() => setDetectionAlert(false)}
      />
      <NonPipeModal
        setNonPipeModal={setNonPipeModal}
        nonPipeModal={nonPipeModal}
        handleCloseNonPipeModal={handleCloseNonPipeModal}
        nonPipeImage={nonPipeImage}
      />
    </>
  );
}

export default Dashboard;
