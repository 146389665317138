import React, { useState, useEffect, useRef } from "react";
import {
  Box,
  Avatar,
  Button,
  IconButton,
  Drawer,
  TextField,
  Typography,
  Menu,
  MenuItem,
  Slider,
  CircularProgress,
} from "@mui/material";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import axios from "axios";
import AvatarEditor from 'react-avatar-editor';

function LinkedInStyleProfile() {
  const [profile, setProfile] = useState(null);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [newProfilePic, setNewProfilePic] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [editorOpen, setEditorOpen] = useState(false);
  const [scale, setScale] = useState(1);
  const [rotate, setRotate] = useState(0);
  const [loading, setLoading] = useState(false);
  const editorRef = useRef(null);

  useEffect(() => {
    fetchProfile();
  }, []);

  const fetchProfile = async () => {
    setLoading(true);
    try {
      const token = localStorage.getItem("isLoggedIn");

      if (!token) {
        alert("Token not found. Please log in.");
        return;
      }

      const response = await axios.get("http://13.202.42.73:8003/user/profile", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      console.log("Profile data fetched:", response.data);
      setProfile(response.data);
    } catch (error) {
      console.error("Error fetching profile:", error);

      if (error.response && error.response.status === 401) {
        alert("Unauthorized access. Please log in again.");
        window.location.href = "/login";
      } else {
        alert("Failed to fetch profile. Please try again.");
      }
    } finally {
      setLoading(false);
    }
  };

  const handleProfilePicSelect = (event) => {
    const file = event.target.files[0];
    setNewProfilePic(file);
    setEditorOpen(true);
  };

  const handleProfilePicUpload = async () => {
    if (editorRef.current) {
      const canvas = editorRef.current.getImageScaledToCanvas();
      canvas.toBlob(async (blob) => {
        const formData = new FormData();
        formData.append("profile_picture", blob, "profile_picture.jpg");

        setLoading(true);
        try {
          const response = await axios.put(
            "http://13.202.42.73:8003/user/profile/picture",
            formData,
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("isLoggedIn")}`,
                "Content-Type": "multipart/form-data",
              },
            }
          );
          setProfile((prevProfile) => ({
            ...prevProfile,
            profile_picture: response.data.profile_picture_url,
          }));
          alert("Profile picture updated successfully!");
          setEditorOpen(false);
        } catch (error) {
          console.error("Error uploading profile picture:", error);
          alert("Failed to upload profile picture.");
        } finally {
          setLoading(false);
        }
      }, "image/jpeg");
    }
  };

  const saveProfile = async () => {
    setLoading(true);
    try {
      const response = await axios.put(
        "http://13.202.42.73:8003/user/profile/update",
        {
          company_email: profile.company_email,
          phone_number: profile.phone_number,
          gstin_number: profile.gstin_number,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("isLoggedIn")}`,
            "Content-Type": "application/json",
          },
        }
      );
      setProfile(response.data);
      toggleDrawer();
      alert("Profile updated successfully!");
    } catch (error) {
      console.error("Error saving profile:", error);
      alert("Failed to update profile.");
    } finally {
      setLoading(false);
    }
  };

  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
      <Box sx={{ position: "relative" }}>
        <IconButton onClick={handleClick}>
          <Avatar
            src={profile?.profile_picture || "/default-profile.png"}
            sx={{ width: 40, height: 40 }}
          />
        </IconButton>
        <IconButton
          sx={{ position: "absolute", bottom: -5, right: -5, backgroundColor: 'white', '&:hover': { backgroundColor: '#f0f0f0' } }}
          component="label"
          size="small"
        >
          <AddPhotoAlternateIcon fontSize="small" />
          <input type="file" hidden onChange={handleProfilePicSelect} accept="image/*" />
        </IconButton>
      </Box>

      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuItem onClick={handleClose}>
          <Typography variant="body2">{profile?.email || "Loading email..."}</Typography>
        </MenuItem>
        {/* <MenuItem onClick={() => {
          handleClose();
          toggleDrawer();
        }}>
          Edit Profile
        </MenuItem> */}
      </Menu>

      <Drawer anchor="right" open={drawerOpen} onClose={toggleDrawer}>
        <Box sx={{ width: 350, p: 4 }}>
          <Typography variant="h6" gutterBottom>Edit Profile</Typography>
          <TextField
            label="Company Email"
            fullWidth
            margin="normal"
            value={profile?.company_email || ""}
            onChange={(e) => setProfile({ ...profile, company_email: e.target.value })}
          />
          <TextField
            label="Phone Number"
            fullWidth
            margin="normal"
            value={profile?.phone_number || ""}
            onChange={(e) => setProfile({ ...profile, phone_number: e.target.value })}
          />
          <TextField
            label="GSTIN Number"
            fullWidth
            margin="normal"
            value={profile?.gstin_number || ""}
            onChange={(e) => setProfile({ ...profile, gstin_number: e.target.value })}
          />
          <Button
            variant="contained"
            fullWidth
            sx={{ mt: 2 }}
            onClick={saveProfile}
          >
            Save Changes
          </Button>
        </Box>
      </Drawer>

      <Drawer anchor="right" open={editorOpen} onClose={() => setEditorOpen(false)}>
        <Box sx={{ width: 350, p: 4 }}>
          <Typography variant="h6" gutterBottom>Edit Profile Picture</Typography>
          {newProfilePic && (
            <AvatarEditor
              ref={editorRef}
              image={newProfilePic}
              width={250}
              height={250}
              border={50}
              borderRadius={125}
              color={[255, 255, 255, 0.6]}
              scale={scale}
              rotate={rotate}
            />
          )}
          <Typography gutterBottom>Zoom</Typography>
          <Slider
            value={scale}
            min={1}
            max={2}
            step={0.01}
            onChange={(e, newValue) => setScale(newValue)}
          />
          <Typography gutterBottom>Rotate</Typography>
          <Slider
            value={rotate}
            min={0}
            max={360}
            step={1}
            onChange={(e, newValue) => setRotate(newValue)}
          />
          <Button
            variant="contained"
            fullWidth
            sx={{ mt: 2 }}
            onClick={handleProfilePicUpload}
          >
            Save Profile Picture
          </Button>
        </Box>
      </Drawer>
    </Box>
  );
}

export default LinkedInStyleProfile;