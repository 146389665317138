import React, { useState, useRef, useEffect } from "react";
import { IconButton, TextField, Button, Box, Modal, Paper, Typography } from "@mui/material";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import axios from "axios";
import moment from "moment";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%",
  height: "90%",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 3,
  overflow: "hidden",
  display: "flex",
  flexDirection: "column",
};

function ImageModal(props) {
  const [circles, setCircles] = useState([]);
  const [truckNumber, setTruckNumber] = useState("");
  const [clickCount, setClickCount] = useState(0);
  const [submitted, setSubmitted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [truckNumberError, setTruckNumberError] = useState("");
  const viewShotRef = useRef();
  

  useEffect(() => {
    if (props.openImageModal) {
      setCircles([]);
      setClickCount(0);
    }
  }, [props.openImageModal]);

  const handleImageClick = (event) => {
    if (!dragging) {
      const { offsetX, offsetY } = event.nativeEvent;
      const newCircle = {
        left: offsetX - 2.5,
        top: offsetY - 2.5,
      };
      setCircles([...circles, newCircle]);
      setClickCount(prevCount => prevCount + 1);
    }
  };

  const handleCircleRightClick = (event, index) => {
    event.preventDefault();
    const newCircles = circles.filter((circle, i) => i !== index);
    setCircles(newCircles);
    setClickCount(prevCount => prevCount - 1);
  };

  let dragging = false;
  let startX = 0;
  let startY = 0;
  const threshold = 5;

  const handleMouseDown = (event) => {
    dragging = false;
    startX = event.clientX;
    startY = event.clientY;
  };

  const getImageSrc = (image) => {
    if (image.startsWith("data:image/")) {
      return image;
    } else {
      return image;
    }
  };

  const handleMouseMove = (event) => {
    if (
      !dragging &&
      (Math.abs(event.clientX - startX) > threshold ||
        Math.abs(event.clientY - startY) > threshold)
    ) {
      dragging = true;
    }
  };

  const handleMouseUp = () => {
    dragging = false;
  };

  const validateTruckNumber = (value) => {
    const regex = /^[a-zA-Z0-9]{5,10}$/;
    if (!regex.test(value)) {
      setTruckNumberError("Truck number should be 5-10 alphanumeric characters");
      return false;
    }
    setTruckNumberError("");
    return true;
  };

  const handleSubmit = async () => {
    if (submitted) return;

    if (!validateTruckNumber(truckNumber)) {
      return;
    }

    setSubmitted(true);
    setIsLoading(true);

    try {
      const token = localStorage.getItem("isLoggedIn");
      if (!token) {
        console.error("Token not found");
        return;
      }

      const headers = {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      };

      const totalCount = props.pipeCount + clickCount;
      const apiUrl = `http://13.202.42.73:8000/manual-count?increment=${clickCount}&processed_image_url=${props.processImage}`;

      const response = await axios.patch(apiUrl, null, {
        headers: {
          ...headers,
          "Content-Type": "application/json",
        },
      });

      if (response.status !== 200) {
        throw new Error("Network request failed");
      }

      const truckNumberUrl = "http://13.202.42.73:8003/add-truck-number";
      const truckNumberData = {
        truck_number: truckNumber,
        processed_image_url: props.processImage,
      };

      await axios.post(truckNumberUrl, truckNumberData, {
        headers: {
          ...headers,
          "Content-Type": "application/json",
        },
      });

      props.handleCloseImageModal();
      window.location.reload();
    } catch (error) {
      console.error("Error updating count or adding truck number:", error);
    } finally {
      setIsLoading(false);
      setSubmitted(false);
    }
  };

  return (
    <Modal
      open={props.openImageModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Box sx={{ display: "flex", justifyContent: "space-between", mb: 2 }}>
          <Typography variant="h6">
            Extra pipes: {props.pipeCount }
            {clickCount > 0 && ` (+${clickCount})`}
          </Typography>
          <Paper elevation={3} sx={{ p: 2, maxWidth: "300px" }}>
            <TextField
              label="Truck Number"
              value={truckNumber}
              onChange={(e) => {
                setTruckNumber(e.target.value);
                validateTruckNumber(e.target.value);
              }}
              error={!!truckNumberError}
              helperText={truckNumberError}
              fullWidth
              margin="normal"
              required
              size="small"
            />
            <Button 
              onClick={handleSubmit} 
              variant="contained" 
              color="primary" 
              disabled={isLoading}
              fullWidth
              sx={{ mt: 1 }}
            >
              {isLoading ? "Submitting..." : "Submit"}
            </Button>
          </Paper>
        </Box>
        <Box sx={{ flexGrow: 1, position: "relative", overflow: "hidden" }}>
          <TransformWrapper
            onMouseDown={handleMouseDown}
            onMouseMove={handleMouseMove}
            onMouseUp={handleMouseUp}
          >
            <TransformComponent>
              <div
                onClick={handleImageClick}
                style={{ width: "100%", height: "100%", position: "relative" }}
              >
                <img
                  src={getImageSrc(props.processImage)}
                  alt="Description of the image"
                  style={{ width: "100%", height: "100%", objectFit: "contain" }}
                />
                {circles.map((circle, index) => (
                  <div
                    key={index}
                    onContextMenu={(event) => handleCircleRightClick(event, index)}
                    style={{
                      position: "absolute",
                      width: "5px",
                      height: "5px",
                      borderRadius: "50%",
                      backgroundColor: "red",
                      left: circle.left,
                      top: circle.top,
                      cursor: "pointer",
                    }}
                  />
                ))}
              </div>
            </TransformComponent>
          </TransformWrapper>
        </Box>
        <IconButton
          style={{
            position: "absolute",
            top: "10px",
            right: "10px",
            backgroundColor: "#000",
            color: "#fff",
          }}
          onClick={props.handleCloseImageModal}
        >
          <CloseRoundedIcon />
        </IconButton>
      </Box>
    </Modal>
  );
}

export default ImageModal;