import React, { useState } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Sidebar from "./Sidebar";
import Dashboard from "./Dashboard";
import PipeReport from "./Stamp/reports/PipeReport";
import sidebarBg from "../assets/images/sidebar-bg.png";
import Redirect from "./Redirect";
import { SwipeableDrawer } from "@mui/material";
import SOP from "./SOP";
import { TextField, Button } from "@mui/material";
import AddCamera from "./AddCamera";
import Profile from "./Profile";
import { useNavigate } from "react-router-dom";

const drawerWidth = 240;
const drawerPaperStyles = {
  backgroundColor: "#252e3e",
  backgroundImage: `url(${sidebarBg})`,
  backgroundAttachment: "fixed",
  backgroundPosition: "left 0 bottom 0 !important",
  backgroundSize: "250px !important",
  backgroundRepeat: "no-repeat",
  padding: "0 20px",
};
const drawerStyles = {
  "& .MuiDrawer-paper": {
    boxSizing: "border-box",
    width: drawerWidth,
  },
};

function NavbarComponent() {
  const [mobileOpen, setMobileOpen] = useState(false);
  const dateObj = new Date();
  const currentDate = dateObj.toISOString().slice(0, 10);
  const [date, setDate] = useState(currentDate);
  const navigate = useNavigate();
  const isLoggedIn = localStorage.getItem("isLoggedIn");

  const handleDateChange = (e) => {
    setDate(e.target.value);
  };

  // Redirect to add camera page if user is logged in for the first time
  if (!isLoggedIn) {
    return <Navigate to="/add-camera" />;
  }

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          width: { lg: `calc(100% - ${drawerWidth}px)` },
          ml: { lg: `${drawerWidth}px` },
          backgroundColor: "#fff",
        }}
      >
        <Toolbar sx={{ display: "flex", alignItems: "center" }}>
          <div style={{ flex: 1 }}>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={() => setMobileOpen(!mobileOpen)}
              sx={{ mr: 2, display: { lg: "none" } }}
            >
              <MenuIcon />
            </IconButton>
            <img
              src={require("../assets/images/Count.png")}
              alt="logo"
              height="30px"
            />
          </div>
          <Button
            onClick={() => navigate("/add-camera")}
            variant="contained"
            sx={{ mr: 2 }}
          >
            Add Camera
          </Button>
          <TextField
            size="small"
            margin="none"
            variant="outlined"
            id="date"
            type="date"
            name="date"
            value={date}
            onChange={(e) => {
              handleDateChange(e);
            }}
            inputProps={{ max: currentDate }}
            sx={{ "& input": { fontSize: "14px" } }}
          />
<Profile>
        </Profile>
        </Toolbar>
      </AppBar>

      <Box
        component="nav"
        sx={{ width: { lg: drawerWidth }, flexShrink: { lg: 0 } }}
        aria-label="mailbox folders"
      >
        {/* Drawer mobile */}
        <SwipeableDrawer
          PaperProps={{
            sx: drawerPaperStyles,
          }}
          variant="temporary"
          open={mobileOpen}
          onOpen={() => setMobileOpen(!mobileOpen)}
          onClose={() => setMobileOpen(!mobileOpen)}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{ ...drawerStyles, display: { xs: "block", lg: "none" } }}
        >
          <Sidebar />
        </SwipeableDrawer>

        {/* Drawer desktop */}
        <Drawer
          PaperProps={{
            sx: drawerPaperStyles,
          }}
          variant="permanent"
          sx={{
            ...drawerStyles,
            display: { xs: "none", lg: "block" },
          }}
          open
        >
          <Sidebar />
        </Drawer>
      </Box>

      {/* Content */}
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          width: {
            lg: `calc(100% - ${drawerWidth}px)`,
            backgroundColor: "rgb(249, 250, 251)",
            height: "100vh",
          },
        }}
      >
        <Toolbar />

        <Routes>
          <Route exact path="/" element={<Redirect />} />
          <Route exact path="/dashboard" element={<Dashboard date={date} />} />
          <Route
            exact
            path="/pipe-reports"
            element={<PipeReport date={date} />}
          />
          <Route exact path="/sop" element={<SOP />} />
          <Route exact path="/add-camera" element={<AddCamera />} />
        </Routes>
      </Box>
    </Box>
  );
}

export default NavbarComponent;
