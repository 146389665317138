import React from "react";
import { Row, Col } from "react-bootstrap";
import { IconButton } from "@mui/material";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import BarChartIcon from "@mui/icons-material/BarChart";
import VideoCameraBackIcon from "@mui/icons-material/VideoCameraBack";
import "../../../styles/stampoverview.scss"; 

function StampOverview(props) {
  return (
    <div className="overview-container">
      <h3>Welcome,</h3>
      <p>Welcome back to ALVision Count</p>
      <Row>
        <Col xs={12} md={4}>
          <div className="stat-card camera-card">
            <IconButton className="icon-btn">
              <VideoCameraBackIcon fontSize="large" />
            </IconButton>
            <div className="stat-value">1</div>
            <div className="stat-label">Camera Count</div>
          </div>
        </Col>
        <Col xs={12} md={4}>
          <div className="stat-card pipe-card">
            <IconButton className="icon-btn">
              <BarChartIcon fontSize="large" />
            </IconButton>
            <div className="stat-value">{props.totalPipes}</div>
            <div className="stat-label">Pipe Count</div>
          </div>
        </Col>
        <Col xs={12} md={4}>
          <div className="stat-card vehicle-card">
            <IconButton className="icon-btn">
              <LocalShippingIcon fontSize="large" />
            </IconButton>
            <div className="stat-value">{props.vehicleCount}</div>
            <div className="stat-label">Vehicle Count</div>
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default StampOverview;
