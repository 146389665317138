import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { Container } from "react-bootstrap";
import { useFormik } from "formik";
import { validationSchema } from "../schemas/EditTruckNumber";
import { Button, TextField } from "@mui/material";
import axios from "axios";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 3,
};

export default function EditTruckNumber(props) {
  const formik = useFormik({
    initialValues: {
      truck_no: "",
    },

    validationSchema: validationSchema,
    onSubmit: async (values) => {
      values.id = props.truckId;
      const res = await axios.post(
        "https://count.alluvium.in/api/update_truck_no",
        values
      );
      props.handleCloseModal();
    },
  });

  const handleTruckNoChange = (event) => {
    const newValue = event.target.value.toUpperCase(); // Convert to uppercase
    formik.handleChange(event); // Handle Formik's onChange event
    formik.setFieldValue("truck_no", newValue); // Update the formik field value
  };

  return (
    <div>
      <Modal
        open={props.openModal}
        onClose={props.handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Edit truck number
          </Typography>
          <form onSubmit={formik.handleSubmit}>
            <Container style={{ margin: "20px 0" }}>
              <TextField
                size="small"
                margin="dense"
                variant="filled"
                fullWidth
                id="truck_no"
                name="truck_no"
                label="Truck No"
                value={formik.values.truck_no}
                onChange={handleTruckNoChange}
                error={
                  formik.touched.truck_no && Boolean(formik.errors.truck_no)
                }
                helperText={formik.touched.truck_no && formik.errors.truck_no}
                inputProps={{ maxLength: 10 }}
              />

              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Button
                  type="submit"
                  className="download-btn"
                  style={{
                    backgroundColor: "#273041",
                    color: "#fff",
                    border: "none",
                    outline: "none",
                    padding: "5px",
                    marginTop: "5px",
                    cursor: "pointer",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  Submit
                </Button>
              </div>
            </Container>
          </form>
        </Box>
      </Modal>
    </div>
  );
}
