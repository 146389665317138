import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 420,
  height: "90%",
  overflow: "scroll",
  bgcolor: "background.paper",
  boxShadow: 24,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justfiyContent: "center",
};

export default function SopModal(props) {
  return (
    <div>
      <Modal
        open={props.sopModal}
        onClose={props.handleCloseSopModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <img src={props.imgSrc} width="100%" alt="" />
        </Box>
      </Modal>
    </div>
  );
}
