import React, { useState } from "react";
import { Container, Row, Col, Modal } from "react-bootstrap";
import SopModal from "../modals/SopModal";
import "../styles/sop.scss"; 
function SOP() {
  const [imgSrc, setImgSrc] = useState("");
  const [sopModal, setSopModal] = useState(false);

  const handleCloseSopModal = () => {
    setSopModal(false);
  };

  const sop = ["/sop1.jpg"];

  return (
    <>
      <Container fluid className="sop-container">
        <Row className="justify-content-center mt-4">
          {sop.map((img, id) => (
            <Col key={id} xs={12} sm={6} md={4} lg={3} className="mb-4">
              <div className="sop-card" onClick={() => {
                setSopModal(true);
                setImgSrc(img);
              }}>
                <img
                  src={img}
                  alt=""
                  className="img-fluid"
                />
              </div>
            </Col>
          ))}
        </Row>
      </Container>

      <SopModal
        sopModal={sopModal}
        handleCloseSopModal={handleCloseSopModal}
        imgSrc={imgSrc}
      />
    </>
  );
}

export default SOP;
